import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { styled } from 'twin.macro';

const BackdropStyled = styled.div`
  && {
    .MuiBackdrop-root {
      z-index: 999999999;
    }
    .MuiCircularProgress-circle {
      stroke: #ffffff;
    }
  }
`;

class SimpleBackdrop extends React.Component {
  render() {
    const { backdrop } = this.props;
    return (
      <BackdropStyled>
        <Backdrop className={''} open={!!backdrop.count}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </BackdropStyled>
    );
  }
}

const mapStateToProps = state => ({
  backdrop: state.backdrop,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SimpleBackdrop);
